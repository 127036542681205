<template>
  <div
    v-shortkey="['esc']"
    class="login-page"
    @click.self="$emit('update:showModalAuth', false)"
    @shortkey="$emit('update:showModalAuth', false)"
  >
    <div class="login-page-container">
      <transition
        name="tns-fade-block"
        mode="out-in"
      >
        <div
          v-if="!showDomainList"
          class="login-page-panel"
        >
          <div
            v-if="ifLoader"
            class="if-loader-crm"
          ></div>
          <template v-if="signInOrganization">
            <transition
              name="fade-modal"
              mode="out-in"
            >
              <div
                v-if="!emailNotFound"
                class="login-page-panel__container"
              >
                <h3 class="login-page-panel__title">
                  Login
                </h3>
                <div class="text-field-wrapper">
                  <div class="form-group form-group_block-statick">
                    <div class="form-group__label">
                      Please Enter your Email Address
                    </div>
                    <div style="position: relative">
                      <input
                        v-model="loginForm.email"
                        v-focus
                        v-shortkey="['enter']"
                        class="text-field"
                        required
                        name="email"
                        type="email"
                        placeholder="Email"
                        @shortkey="loginUser()"
                      />
                      <div class="help-block help-block_top with-errors">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="login-page-panel__footer">
                  <button
                    class="btn btn_lg-width"
                    @click.prevent="loginUser()"
                  >
                    Next
                  </button>
                </div>
              </div>
              <div
                v-if="emailNotFound"
                class="login-page-panel__container login-page-panel__container_not-found"
              >
                <h3 class="login-page-panel__title login-page-panel__title_error">
                  There is no client in our database with that email address.
                  <span>
                    Please try different email or contact <a href="maito:support@impactfactors.net">support@impactfactors.net</a>
                  </span>
                </h3>
              </div>
            </transition>
          </template>
        </div>
        <div
          v-else
          class="login-page-panel login-page-panel_list"
        >
          <div class="login-page-panel__container login-page-panel__container_list">
            <h3 class="login-page-panel__title">
              Please select which Admin you want to use?
            </h3>
            <div class="login-page-panel-account-list">
              <div
                v-for="(item, index) in accountList"
                :key="index + '_list'"
                class="login-page-panel-account-list-item"
              >
                <div class="login-page-panel-account-list-item__title">
                  {{ item.title }}
                </div>
                <button
                  class="btn"
                  @click="goToAdmin(item.link)"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import authApi from '@/api/authApi';

export default {
  name: 'ModalsAuth',
  props: ['showModalAuth'],
  data() {
    return {
      signInOrganization: true,
      rememberMe: false,
      redirectUrl: '',
      successForgotMessage: '',
      forgotErrMess: '',
      forgotPassword: false,
      forgotPasswordOrg: false,
      forgotPassEmail: '',
      logout: false,
      loginForm: {
        email: '',
        password: '',
      },
      loginError: '',
      accountList: [],
      showDomainList: false,
      emailNotFound: false,
      ifLoader: false,
    };
  },
  methods: {
    goToAdmin(link) {
      const linkA = document.createElement('a');
      linkA.setAttribute('target', '_blank');
      linkA.setAttribute('href', `${link}admin/login`);
      linkA.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.$emit('update:showModalAuth', false);
    },
    loginUser() {
      this.ifLoader = true;
      window.fbq('trackCustom', 'Existing Client Login');
      authApi.getAvailableAdminDomains(this.loginForm.email).then((resp) => {
        this.ifLoader = false;
        if (resp.data.domain.length === 0) {
          this.emailNotFound = true;
          setTimeout(() => {
            this.emailNotFound = false;
          }, 1500);
        } else {
          this.showDomainList = true;
          this.accountList.push(...resp.data.domain);
        }
      }).catch((err) => {
        this.ifLoader = false;
        console.log(err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/utils/variables";
  @import "../../assets/sass/utils/fields";
  @import "../../assets/sass/utils/transition";

  .login-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    background: $color-concrete;

    &__text {
      font-family: $font-third, sans-serif;
      color: $color-dusty-gray-dark;
      font-size: 14px;

      i {
        color: $color-shamrock-second;
        font-size: 21px;
        margin-right: 5px;
        position: relative;
        top: 2px;

        &:before {
          margin: 0;
        }
      }

      span {
        letter-spacing: -0.0875px;
        margin-right: 5px;
      }

      a {
        color: $color-gray-chateau;
        border-bottom: 1px solid $color-gray-chateau;
        transition: opacity .15s ease-in;

        &:hover {
          opacity: .6;
        }
      }
    }

    &__img {
      font-size: 0;

      &_logout {
        img {
          max-height: 370px !important;
          height: 100% !important;
          max-width: 310px;
        }
      }

      img {
        max-height: 280px;
        height: 100%;
      }
    }

    &-panel {
      position: relative;
      padding: 25px 24px 24px;
      border-radius: 4px;
      background-color: $color-white;
      box-shadow: 0 5px 20px rgba(45, 48, 49, 0.2021);
      width: 376px;
      max-width: 100%;
      min-height: 337px;
      height: 337px;
      display: flex;
      align-items: center;
      justify-content: center;

      &_list {
        background-color: transparent;
        width: 523px;
        display: block;

        .login-page-panel__title {
          text-align: center;
          color: $color-white;
        }

      }

      &__container {
        width: 100%;

        &_list {
          .login-page-panel__title {
            margin-bottom: 40px;
          }
        }

        &_not-found {
          .login-page-panel__title {
            margin-bottom: 0;
            text-align: center;
          }
        }

      }

      &_logout {
        height: 388px !important;
      }

      &_forgot {
        margin-top: 20px;
      }

      &__close {
        position: absolute;
        top: 23px;
        right: 16px;
        font-size: 16px;
        color: $color-jumbo;
        transition: transform .15s ease-in;
        cursor: pointer;

        &:hover {
          transform: rotate(90deg);
        }
      }

      &__footer {
        margin-top: 74px;

        .btn {

          .btn__content {
            line-height: inherit;
          }
        }

        &_forgotPassword {
          margin-top: 32px;
        }

      }

      &__forgot {
        font: 14px $font-third;
        cursor: pointer;
        color: $color-gray-chateau;
        margin-top: 6px;

        &_org {
          margin-top: 16px;
          margin-bottom: 32px;
        }

        span {
          display: inline-block;
          padding-bottom: 2px;
          border-bottom: 1px solid $color-gray-chateau;
          letter-spacing: 0.3px;
        }

        &:hover {
          span {
            border-bottom-color: transparent;
          }
        }
      }

      &__title {
        font-family: $font-third-medium;
        font-size: 21px;
        margin-bottom: 72px;

        &_error {
          line-height: 1.3;

          span {
            display: block;
            margin-top: 15px;
            font-size: 18px;
          }
        }

        &-icon {
          display: block;
          margin-right: 6px;
          color: $color-gray-chateau;
          font-size: 8px;
          transform: rotate(180deg);
          position: relative;
          top: -2px;
          cursor: pointer;
          transition: color .2s ease-in;

          &:hover {
            color: $color-turquoise;
          }
        }

      }

      .text-field-wrapper + .text-field-wrapper {
        margin-top: 21px;
      }
    }
  }

  .forgot-confirm {
    height: 32px;
    text-align: center;
    color: $color-turquoise;
    font-size: 14px;
    font-family: $font-third;
    padding-top: 6px;
  }

  .form-error-notification {
    font-family: $font-third;
    font-size: 13px;
    color: $color-cardinal;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/sass/utils/variables";

  .login-page {
    position: fixed;
    top: 0;
    left: 0;
    /*width: 100vw;*/
    /*height: 100vh;*/
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color-black, .9);
    padding: 15px;

    &-container {
      max-width: 100%;
      height: 337px;
      overflow: hidden;
    }

  }

  .form-group_horizontal {

    .text-field {
      margin-left: 0;
      margin-right: 8px;
      width: 200px !important;
      min-width: 200px !important;
    }

    .form-group__text {
      font-family: $font-third-medium;
    }

  }

  .form-group__label {
    width: 100%;
    font-family: $font-third;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 9px;
  }

  .text-field {
    width: 100%;
    padding-left: 7px !important;
    padding-top: 2px !important;
  }

  .logout-successful {
    height: 50px;
    margin-bottom: 30px;

    &_small {
      height: 38px;
    }

    &__content {
      display: flex;
      align-items: center;
      background: $color-chateau-green;
      height: 100%;
      margin: 0 -24px;
      padding: 0 24px;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      min-width: 28px;
      height: 28px;
      margin-right: 17px;
      border-radius: 50%;
      background-color: $color-white;
      color: $color-chateau-green;
      font-size: 8px;
    }

    &__text {
      font: 14px/17px $font-third;
      color: $color-white;
    }

  }

  .btn {
    margin-top: 30px;
    background: $color-turquoise;
    height: 40px;
    border: none;
    font-size: 14px;
    font-family: $font-third, sans-serif;
    color: $color-white;
    padding: 0 6px;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    text-decoration: none;
    transition: opacity .2s ease-in, background .2s ease-in;
    user-select: none;
    appearance: none;
    text-transform: uppercase;

    &_disabled {
      background: $color-gray;
      pointer-events: none;
    }

    &:hover {
      opacity: .8;
    }

    &:active {
      background: $color-elm;
      opacity: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, .5);
      opacity: 0;
      border-radius: 100%;
      transform: scale(1, 1) translate(-50%);
      transform-origin: 50% 50%;
    }

    @keyframes ripple {
      0% {
        transform: scale(0, 0);
        opacity: 1;
      }
      20% {
        transform: scale(25, 25);
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: scale(40, 40);
      }
    }

    &:focus:not(:active)::after {
      animation: ripple 1.5s ease-out;
    }
  }

  .login-page-panel-account-list-item {
    display: flex;
    align-items: center;
    width: 475px;
    min-width: 475px;
    justify-content: space-between;
    padding: 15px;
    background: $color-white;
    border-radius: 3px;

    & + & {
      margin-top: 7px;
    }

    &__title {
      font: 18px $font-third-medium;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn {
      width: 80px;
      min-width: 80px;
      margin-left: 15px;
      margin-top: 0;
    }
  }

  .if-loader-crm {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 100;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 65;
      width: 100%;
      height: calc(100% + 1px);
      top: 0;
      left: 0;
      margin-top: -1px;
      background-color: rgba($color-black, 0.2);
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 66;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -100px);
      color: $color-turquoise;
      font-size: 10px;
      margin: 100px auto;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      text-indent: -9999em;
      animation: loader 1.3s infinite linear;
    }

    &__series {
      height: 150px;

      &:before {
        background: none;
      }
    }

    .not-found-placeholder {
      opacity: 0;
    }

    &_absolute {
      position: absolute;
    }
  }

  @keyframes loader {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  .tns-fade-block {
    &-enter-active,  &-leave-active, &-leave-to {
      transition: .3s ease-out;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
      transition: 30s ease-out;
    }
  }

</style>
