import * as domains from '@/constants/domains';

const axios = require('axios');

export default {
  getAvailableAdminDomains(email) {
    const instWithCred = axios.create({
      baseURL: domains.CORP_DOMAIN,
    });
    return instWithCred.get(`find-admin?email=${email}`);
  },
};
